import React, {FC, useEffect, useState} from "react";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import Link from "next/link";
import {BlogSVG, ContactusSVG, HotelCloseMenu, TrackingSVG} from "../../svg";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {changeLocale} from "../../store/locale";

import {mobileMenuClose} from "../../store/mobile-menu";
import {IItem} from "../../types/header";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {apiImageUrl} from "../../helper";
import {setWindowSize} from "../../store/general";
import {useRouter} from "next/router";


const HotelMobileLinks = dynamic(() => import('./Hotel_MobileLinks'))
const MobileHeader = dynamic(() => import('../mobile/MobileHeader'))
const MobileMenuFooter = dynamic(() => import('../mobile/MobileMenuFooter'))
const MobileLinksForPages = dynamic(() => import('../mobile/MobileLinksForPages'))
const HotelDropdownLanguage = dynamic(() => import('./Hotel_DropdownLanguage'))


const HotelDepartmentMenu: FC = () => {
    const dispatch = useDispatch();
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const windowSize = useSelector((state: IState) => state.general.windowSize)
    const hasBlog = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_blog_active?.value)))
    const hasTrackingActive = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.sales_tracking_tracking_active?.value)))
    const hasContactWidthUs = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_contact_us_active?.value)));
    // const locale = useSelector((state: IState) => state.locale);
    const languages = useSelector((state: IState) => state.locale.list);
    const locale = useSelector((state: IState) => state.locale);
const router = useRouter()
    const mobileMenuState = useSelector((state: IState) => state.mobileMenu);
    const menuPagesList = useSelector((state: IState) => state.mobileMenu.mobileMenuList);
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)
        setIsMobile(windowSize <= 425)
    }, [windowSize])

    const pages = <FormattedMessage id="pages" defaultMessage="Pages"/>;
    const category = (
        <FormattedMessage id="categoies" defaultMessage="Category"/>
    );

    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.open,
    });

    const navLinksMobile = useSelector((state: IState) =>
        state.general.menuList?.filter((items: {
            type: string;
        }) => items.type == "header").sort((a, b) => Number(a.position) - Number(b.position))
    );
    const navLinksMobileFooter = useSelector((state: IState) =>
        state.general.menuList?.filter((items) => items.type == "footer").sort((a, b) => Number(a.position) - Number(b.position))
    );

    const together = [...navLinksMobile, ...navLinksMobileFooter]
    const handleItemClick = (item?: { data?: { type: string; locale: string; }; type?: string; }) => {

        if (item?.data) {
            if (item.data.type === "language") {
                dispatch(changeLocale(item.data.locale));
                dispatch(mobileMenuClose());
            }
        }
        dispatch(mobileMenuClose());
    };

    const statecategories = useSelector((state: IState) => state.general.categories);
    const categories = [{label: category, children: statecategories}];

    const handleRoute = (locale: any) => {
        return router.push(
            router.asPath !== "/" ? router.asPath : "",
            router.asPath !== "/" ? router.asPath : "",
            {locale: locale.code}
        );
    };

    return (
        <div className={classes} style={{opacity: mobileMenuState.open ? 1 : 0}}>
            <div className="mobilemenu__backdrop" onClick={() => dispatch(mobileMenuClose())}/>
            <div className="mobilemenu__body">
                <div className="menu__header">
                    <div className="header__logo">
                        {
                            domain ? (
                                    <Link href="/">
                                        <a aria-label="go to home page">
                                            <LazyLoadImage
                                                alt="mobile-logo"
                                                height={45}
                                                src={`/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`}
                                                onError={(e) => {
                                                    // @ts-ignore

                                                    e.target.src = `${apiImageUrl}/vendor/webkul/ui/assets/images/logo.svg?v=${cacheVersion}`;
                                                }}
                                            />
                                        </a>
                                    </Link>
                                )
                                : (
                                    <Link href="/">
                                        <a aria-label="go to home page">
                                            <LazyLoadImage
                                                alt=""
                                                width={150}
                                                height={45}
                                                src={`${apiImageUrl}/vendor/webkul/ui/assets/images/logo.svg`}
                                            />
                                        </a>
                                    </Link>
                                )
                        }
                    </div>
                    <button
                        type="button"
                        className="mobile-header__menu-button"
                        onClick={() => dispatch({type: "MOBILE_MENU_OPEN", payload: false})}
                        aria-label="MobileHeaderBtn"
                    >
                        <HotelCloseMenu/>
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <HotelMobileLinks links={statecategories as IItem[]} onItemClick={handleItemClick}/>
                    {isTablet && menuPagesList ? (
                        <>
                            <MobileLinksForPages
                                links={[...together]}
                                onItemClick={handleItemClick}
                            />

                            {
                                hasBlog ? (
                                        <ul className="mob-links__item">
                                            <Link href="/blog">
                                                <a className="mob-links__blok-a"
                                                   onClick={() => dispatch(mobileMenuClose())}>
                                                <span className="mobile-links__blok">
                                                    {/*<BlogSVG/>*/}
                                                    <FormattedMessage id="blog" defaultMessage="Blog"/>
                                                </span>
                                                </a>
                                            </Link>
                                        </ul>
                                    ) :
                                    <></>
                            }
                            {hasContactWidthUs && (
                                <div
                                    className="mob-links__item"
                                >
                                    <Link href="/contact">
                                        <a
                                            className="mob-links__blok-a"
                                            onClick={() => dispatch(mobileMenuClose())}
                                        >
                                            <span className="mobile-links__blok">
                                            {/*<ContactusSVG/>*/}
                                                <FormattedMessage
                                                    id="contact_with_us"
                                                    defaultMessage="Contact With Us"
                                                />
                                            </span>
                                        </a>

                                    </Link>
                                </div>
                            )}
                            {hasTrackingActive && (
                                <div
                                    className="mob-links__item"
                                    style={{borderBottom: "none"}}
                                >
                                    <Link href="/tracking">
                                        <a
                                            className="mob-links__blok-a"
                                            onClick={() => dispatch(mobileMenuClose())}
                                        >
                                            <span className="mobile-links__blok">
                                                {/*<TrackingSVG/>*/}
                                                <FormattedMessage
                                                    id="tracking"
                                                    defaultMessage="Tracking Number"
                                                />
                                            </span>
                                        </a>
                                    </Link>
                                </div>
                            )}
                            {isMobile && <div className="language_list">
                                {languages?.map((el, index) => {
                                    return <div key={index} className="lang_image" onClick={() => handleRoute(el)}>
                                        <LazyLoadImage
                                            height={32}
                                            width={32}
                                            alt="language"
                                            src={
                                                el.locale_image
                                                    ? `/storage/${domain}/${el.locale_image}`
                                                    : `/vendor/webkul/ui/assets/images/flag_${el.code}.svg`
                                            }
                                        />
                                    </div>
                                })}
                            </div>
                            }
                        </>
                    ) : (
                        ""
                    )}
                </div>
                {/*<div className="mobileMenuFooter-fms">*/}
                {/*    <MobileMenuFooter />*/}
                {/*</div>*/}
            </div>
        </div>
    );
}


export default HotelDepartmentMenu
